import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { API_ROOT } from "../../config/env-var";
// import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import Footer from "../footer/Footer";
import Banner from "../header/Banner";
import Header from "../header/Header2";
import TicketFilters from "./TicketFilters";
import TicketList from "./TicketList";

const TicketsListView = () => {
  let location = useLocation();
  let event_id = new URLSearchParams(location.search).get("event_id");
  console.log(location, event_id);
  const [ticketsData, setTicketsData] = useState([]);
  const [data, setData] = useState([]);
  const [event, setEvent] = useState();
  const [streams, setStreams] = useState([]);

  const fetchEvent = async () => {
    let tickets = await axios
      .get(`${API_ROOT}/tournaments/${event_id}`)
      .catch(console.log);

    setEvent(tickets.data[0]);
  };

  const fetchTickets = async () => {
    let tickets = await axios
      .get(`${API_ROOT}/tournaments/tickets/${event_id}`)
      .catch(console.log);

    setTicketsData(tickets.data);
    setData(tickets.data);
    let tempStreams = [];
    tickets.data.map((value) => {
      if (tempStreams.indexOf(value.stream_name) < 0) {
        tempStreams.push({ name: value.stream_name, included: 1 });
      }
    });
    tempStreams.sort((a, b) => {
      return a.name.charCodeAt(0) - b.name.charCodeAt(0);
    });
    setStreams(tempStreams);
  };

  useEffect(() => {
    fetchEvent();
    fetchTickets();
  }, [event_id]);

  return (
    <>
      {" "}
      <MetaTags>
        <title>Tickets | STRIPE Competition</title>
        <meta name="description" content="Sign Up for STRIPE Competition" />
      </MetaTags>
      <Header />
      {event ? (
        <Banner link={"tickets?event_id=" + event_id} title={event.name} />
      ) : (
        <Banner title={"Register"} />
      )}
      <main>
        <section className="pt-20 pb-110">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                {
                  <TicketFilters
                    originalData={ticketsData}
                    setData={setData}
                    streams={streams}
                    setStreams={setStreams}
                  />
                }
              </div>
              <div className="col-lg-8">
                {event && (
                  <TicketList data={data} event={event} streams={streams} />
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
export default TicketsListView;
