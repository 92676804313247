import axios from "axios";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useLocation } from "react-router-dom";
import { API_ROOT, CONVERGE } from "../../config/env-var";
import Footer from "../footer/Footer";
import Banner from "../header/Banner";
import Header from "../header/Header2";
import CheckoutAside from "./CheckoutAside";
import CheckoutForm from "./CheckoutForm";

// import { useHistory } from "react-router";

const Checkout = () => {
  let location = useLocation();
  let id = new URLSearchParams(location.search).get("event_id");
  // let history = useHistory();
  const [ticket, setTicket] = useState();
  const [event, setEvent] = useState();
  const [discount, setDiscount] = useState();
  const fetchTicket = async () => {
    let tickets = await axios
      .get(`${API_ROOT}/tournaments/ticket/${id}`)
      .catch(console.log);

    setTicket(tickets.data[0]);
    let event = await axios
      .get(`${API_ROOT}/tournaments/${tickets.data[0].event_id}`)
      .catch(console.log);

    setEvent(event.data[0]);
  };

  useEffect(() => {
    fetchTicket();
  }, []);

  const convergeCheckout = async (
    trans_id,
    fname,
    lname,
    email,
    phone,
    address1,
    address2,
    country,
    city,
    state,
    zip,
    country_code,
    enteredKey,
    key,
    value,
    total
  ) => {
    /*
    history.push({
      pathname: `/payment-success/${trans_id}`,
    });
    */
    let realTotal =
      total -
      (discount
        ? discount.coupon_type_id === 1
          ? discount.coupon_value
          : total * (discount.coupon_value / 100)
        : 0);
    realTotal = realTotal > 5 ? realTotal : 5;
    axios
      .get(
        `${API_ROOT}/transactions?organization_id=${
          event.organization_id
        }&amount=${realTotal.toFixed(
          2
        )}&trans_id=${trans_id}&fname=${fname}&lname=${lname}&email=${email}&phone=${phone}&address1=${address1}&address2=${address2}&country=${country}&city=${city}&state=${state}&zip=${zip}&country_code=${country_code}&enteredKey=${enteredKey}&key=${key}&value=${value}`
      )
      .then((response) => {
        window.location.href = `${CONVERGE}${response.data}`;
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  return (
    <>
      <MetaTags>
        <title>Checkout | STRIPE Competition</title>
        <meta name="description" content="Sign Up for STRIPE Competition" />
      </MetaTags>
      <Header />
      <Banner title="Checkout" hideBreadcrumbs={true} />
      <main>
        <section className="pb-110">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                {ticket && event && (
                  <CheckoutForm
                    event={event}
                    ticket={ticket}
                    convergeCheckout={convergeCheckout}
                  />
                )}
              </div>
              <div className="col-lg-4">
                {ticket && (
                  <CheckoutAside
                    discount={discount}
                    setDiscount={setDiscount}
                    event={event}
                    ticket={ticket}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};
export default Checkout;
