import React from "react";
import MetaTags from "react-meta-tags";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "../footer/Footer";
import Banner from "../header/Banner";
import Header from "../header/Header2";
import StreamScoreboard from "./StreamScoreboard";

const ScoreboardList = () => {
  return (
    <>
      <MetaTags>
        <title>STRIPE Competition</title>
        <meta
          name="description"
          content="STRIPE Competition is an open STEM competition encompassing
        science, technology, robotics, innovation, programming, and engineering. Our competition
        allows students to show case their skills in the field of STEM while
        applying their knowledge to real-world problems in an inclusively diverse cultural environment."
        />
      </MetaTags>
      <Header />
      {/* Breadcrumbs */}
      <Banner title="Scoreboards" />
      {/* About Us Sec */}
      <section className="sec-space">
        <div className="container">
          <StreamScoreboard />
        </div>
      </section>
      <Footer />
    </>
  );
};
export default ScoreboardList;
