import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import MetaTags from "react-meta-tags";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { API_ROOT } from "../../../config/env-var";
import Footer from "../../footer/Footer";
import Banner from "../../header/Banner";
import Header from "../../header/Header2";
import { AddScore } from "./Modals/AddScore";
import { DeleteScore } from "./Modals/DeleteScore";
import ScoreFilters from "./ScoreFilters";
import ScoreTable from "./ScoreTable";

const ScoreList = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [origData, setOrigData] = useState([]);
  const [displayRange, setDisplayRange] = useState({ start: 0, end: 5 });
  const [displayCount, setDisplayCount] = useState(5);
  const [scoreRowData, setScoreRowData] = useState([]);

  const fetchScores = async () => {
    let scores = await axios.get(`${API_ROOT}/scores/raw`).catch(console.log);
    setOrigData(scores.data.results);
    setScoreRowData(scores.data.results);
  };
  useEffect(() => {
    fetchScores();
  }, []);

  const toggleAddModal = () => {
    let currShow = showAdd;
    setShowAdd(!currShow);
  };
  const hideDeleteModal = () => {
    setShowDelete(false);
    fetchScores();
  };
  const showDeleteModal = (id) => {
    setShowDelete(id);
  };
  return (
    <>
      <MetaTags>
        <title>STRIPE Competition</title>
        <meta
          name="description"
          content="STRIPE Competition is an open STEM competition encompassing
        science, technology, robotics, innovation, programming, and engineering. Our competition
        allows students to show case their skills in the field of STEM while
        applying their knowledge to real-world problems in an inclusively diverse cultural environment."
        />
      </MetaTags>
      <Header />
      {/* Breadcrumbs */}
      <Banner title="Score Management" />
      {/* About Us Sec */}
      <section className="container">
        <AddScore showAdd={showAdd} toggleAddModal={toggleAddModal} />
        <DeleteScore
          showDelete={showDelete}
          hideDeleteModal={hideDeleteModal}
          showDeleteModal={showDeleteModal}
        />
        <Card className="mb-20">
          <Card.Header>
            <div className="row">
              <div className="col-3">
                <h3>Filters</h3>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ScoreFilters
              origData={origData}
              scoreRowData={scoreRowData}
              setScoreRowData={setScoreRowData}
            />
          </Card.Body>
        </Card>
        <Card className="mb-20">
          <Card.Header>
            <div className="row">
              <div className="col-3">
                <h3>Scores</h3>
              </div>
              <div className="col-3"></div>
              <div className="col-4"></div>
              <div className="col-2">
                <button
                  onClick={() => {
                    setShowAdd(true);
                  }}
                  className="btn btn-success"
                >
                  + Add New Score
                </button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ScoreTable
              scoreRowData={scoreRowData}
              displayRange={displayRange}
              displayCount={displayCount}
              setDisplayRange={setDisplayRange}
              setDisplayCount={setDisplayCount}
              showDeleteModal={showDeleteModal}
            />
          </Card.Body>
        </Card>
      </section>
      <Footer />
    </>
  );
};
export default ScoreList;
