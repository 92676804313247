import React from "react";
import MetaTags from "react-meta-tags";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "../footer/Footer";
import Banner from "../header/Banner";
import Header from "../header/Header2";

const About = () => {
  return (
    <>
      <MetaTags>
        <title>STRIPE Competition</title>
        <meta
          name="description"
          content="STRIPE Competition is an open STEM competition encompassing
          science, technology, robotics, innovation, programming, and engineering. Our competition
          allows students to show case their skills in the field of STEM while
          applying their knowledge to real-world problems in an inclusively diverse cultural environment."
        />
        <link rel="canonical" href="https://www.stripecompetition.com/About" />
      </MetaTags>
      <Header />
      {/* Breadcrumbs */}
      <Banner title="About" />
      {/* About Us Sec */}
      <section className="sec-space">
        <div className="container">
          <div className="sec-heading text-center about-text">
            <h2 className="line text-uppercase  position-relative">
              S.T.R.I.P.E Competition
            </h2>
          </div>
          <p className="mb-0 fs-18">
            Competition fosters innovation by pushing individuals and teams to
            develop creative and efficient solutions to complex problems. STRIPE
            Competition was designed to fuel this innovation and provide more
            opportunity for practice, trouble-shooting, and collaboration, much
            like sports teams experience in a season of play. Practice in
            competition helps young people develop resilience and maturity while
            they strive to outperform their rivals. Competitive environments
            also provide opportunities for networking and collaboration, as
            individuals share ideas and knowledge. STRIPE Competition are held
            in various North American locations.
            <br />
          </p>
          <div className="sec-heading text-center about-text mt-45">
            <h3 className="line text-uppercase  position-relative">
              Why choose STRIPE Competition?
            </h3>
          </div>
          <p className="mb-0 fs-18 mt-35">
            At STRIPE Competition, we value not just the winning but the human
            skills developed through competition. We strive to create inclusive,
            engaging, challenging, and supportive competitions that allow
            students to test new ideas, learn from failure, and become invested
            in the spirit of innovation. We award winners, but we develop mature
            competitors who will be able to collaborate with others and approach
            challenges with persistence and grit.
            <br />
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default About;
