import React from "react";

const Venue = ({ myInfoSection }) => {
  return (
    <div className="sec-space bg-light-blue" ref={myInfoSection}>
      <div className="container">
        <div className="row">
          <div className="sec-heading text-center">
            <h2 className="fs-45 mb-40">
              Welcome to the STRIPE Competition League
            </h2>
          </div>
        </div>
        <div className="row">
          <p>
            STRIPE Competition is an exciting competition series for students in
            robotics, programming, and innovation. The name stands for Science,
            Technology, Robotics, Innovation, Programming, and Engineering. The
            imaginative themes and innovative challenges are designed to ignite
            the creative spirit of young minds, fostering innovation and
            problem-solving skills through competition. STRIPE Competition was
            created to provide even more opportunities for students to showcase
            their talents, collaborate with peers, and tackle real-world
            challenges using technology.
          </p>
          <p>
            For the 2023-2024 season, there will be two division tournaments,
            one in the fall and one in the winter, where students can compete in
            programming and robotics. In the summer, the championship tournament
            will include innovation as well as programming and robotics. Teams
            who participated in the division tournaments will be eligible for a
            division award, but anyone can participate at any time!
          </p>
        </div>
        <div className="row align-center">
          <img
            className="venue-photo"
            alt="Season Breakdown"
            src="assets/images/SeasonBreakdown.png"
          />
        </div>
        <div className="row">
          <div className="col-lg-4"></div>
          <div className="col-lg-4 align-center">
            {" "}
            <a
              className="theme-btn-secondary w-100 text-center"
              href={"/2024season"}
              title=""
            >
              Find a tournament<span></span>
            </a>
          </div>
          <div className="col-lg-4"></div>
        </div>
      </div>
    </div>
  );
};
export default Venue;
