import React from "react";
import MetaTags from "react-meta-tags";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "../footer/Footer";
import Banner from "../header/Banner";
import Header from "../header/Header2";

const Ethics = () => {
  return (
    <>
      <MetaTags>
        <title>STRIPE Competition</title>
        <meta
          name="description"
          content="STRIPE Competition is an open STEM competition encompassing
          science, technology, robotics, innovation, programming, and engineering. Our competition
          allows students to show case their skills in the field of STEM while
          applying their knowledge to real-world problems in an inclusively diverse cultural environment."
        />
        <link rel="canonical" href="https://www.stripecompetition.com/ethics" />
      </MetaTags>
      <Header />
      {/* Breadcrumbs */}
      <Banner title="Ethics" />
      {/* Ethics Sec */}
      <section className="sec-space">
        <div className="container">
          <div className="sec-heading text-center about-text">
            <h2 className="line text-uppercase position-relative">
              Our Ethics
            </h2>
          </div>
          <div className="row">
            <p className="mb-20 fs-18">
              <br />
              This series is built around our STRIPE Competition ethics, which
              are vital for competitors and for the organization as a whole. The
              STRIPE Competition ethics are made up of:
            </p>
          </div>
          <div className="row overlay">
            <div className="col-md-4 my-auto">
              <div className="row">
                <img src="assets/images/respect.png" alt="" />
              </div>
            </div>
            <div className="col-md-6 my-auto mx-auto">
              <div className="row">
                <div className="sec-heading text-center">
                  <h2 className="text-white">Respect</h2>
                </div>
              </div>
              <div className="row">
                <div className="">
                  <p className="text-white ethics-para">
                    Respect is vital for successful competitions. There are many
                    people who make competitions possible, and each of them
                    deserves respect. We need to show respect to other
                    competitors, judges, volunteers, and ourselves.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row overlay2">
            <div className="col-md-4">
              <img src="assets/images/collaboration.png" alt="" />
            </div>

            <div className="col-md-6 my-auto mx-auto">
              {" "}
              <div className="row">
                <div className="sec-heading text-center">
                  <h2 className="theme-color">Collaboration</h2>
                </div>
              </div>
              <div className="row">
                <div className="">
                  <p className="text-white ethics-para">
                    Collaboration is essential to our competitions, for while
                    learning can be done individually, applying it to the real
                    world requires teamwork. In STEM fields, it is common to
                    work with a team, and in STRIPE Competition, we prepare
                    students for that experience by giving them opportunities to
                    work in groups to compete.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row overlay3">
            <div className="col-md-4">
              <img src="assets/images/perseverance.png" alt="" />
            </div>
            <div className="col-md-6 mx-auto my-auto">
              <div className="row">
                <div className="sec-heading text-center">
                  <h2 className="theme-color">Perseverance</h2>
                </div>
              </div>
              <div className="row">
                <div className="">
                  <p className="text-white ethics-para">
                    In STEM fields, one of the most important lessons is not
                    giving up. Failure is part of the process. Solving a bug,
                    creating the perfect mechanism, or trying to perfect a
                    project involves failure. When we understand this, we can
                    truly begin to find solutions. This is why STRIPE
                    Competition believes perseverance is important in all we do.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Ethics;
