import React from "react";
import MetaTags from "react-meta-tags";
import { ReactComponent as RoboticsIcon } from "../../svg/robotics.svg";
import Footer from "../footer/Footer";
import Banner from "../header/Banner";
import Header from "../header/Header2";
import ChallengeStrip from "./CategoryDetails/ChallengeStrip";

const Robotics = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Robotics Challenge | STRIPE Competition</title>
        <meta
          name="description"
          content="Join the STRIPE Competition Robotics Challenge for students ages 9 - 15.
            In this challenge students will use Lego Robotics to complete missions in the theme of Sustainability."
        />
        <link
          rel="canonical"
          href="https://www.stripecompetition.com/Robotics"
        />
      </MetaTags>
      <Header />
      {/* <!-- Breadcrumbs --> */}
      <Banner title="Robotics" />
      <main>
        <section className="pt-60 pb-30">
          <div className="container">
            <p className="fs-18">
              Robotics is an essential part of our modern society. As we push
              the limits of our understanding of technology and create new and
              more advanced tools, it becomes increasingly important for
              students to learn to interact with these tools at a young age. At
              STRIPE Competition, we use robotics competitions to help students
              practice their programming and engineering skills while also
              allowing them to challenge themselves. In the robotics challenges,
              we are focused on exposing students to real-world engineering
              problems.
            </p>
          </div>
        </section>
        {/* RecipeDetail */}
        <section>
          <div className="container category-card category-bg-1">
            <div className="row">
              <ChallengeStrip
                icon={<RoboticsIcon />}
                category_name="Autonomous Challenge - Nebula"
                age="7 - 8"
                team_size="2-3 members"
                third_title="Robot"
                third_text="Lego WeDo 2.0"
              />
            </div>
            <div className="row">
              <div className="col-lg-4">
                <img
                  src="assets/images/nebula_robotics.png"
                  alt="Nebula Robotis"
                  width="100%"
                />
              </div>
              <div className="col-lg-8">
                <div className="recipe-detail-content">
                  <div className="category-card-desc">
                    <p className="fs-18">
                      The Jr. Robotics Challenge is an opener for junior kids to
                      the world of robotics in the STRIPE Competition; this
                      category allows students to understand competition, learn
                      the concepts, and to showcase their ideas. On the day of
                      the competition, teams will be given their robot
                      challenge. The challenge will require students to build
                      and program their robot to complete missions on an Alien
                      Planet. Students will work in teams of 2-3.
                    </p>
                    <br />

                    <p>
                      <a className="theme-btn-secondary" href="/2024season">
                        Register<span></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container category-card category-bg-2">
            <div className="row">
              <ChallengeStrip
                icon={<RoboticsIcon />}
                category_name="Autonomous Challenge - Star"
                age="9-12"
                team_size="2-3 members"
                third_title="Robot"
                third_text="Lego Mindstorm EV3 | Lego Spike Prime"
              />
            </div>
            <div className="row">
              <div className="col-lg-8">
                <div className="recipe-detail-content">
                  <p className="fs-18">
                    Teams will prepare for the advance challenge by checking
                    “Season Challenge” on the Seasons page under "Aien Planet".
                    In addition, there will be a day-of challenge that will
                    require teams to design, build, and program their robot to
                    complete a mission within the theme of exploring an Alien
                    Planet. Judging will be based on both challenges.
                  </p>
                  <p className="fs-18">
                    For this category, students should bring at least 1 laptop
                    per team. In addition,
                  </p>
                  <ul>
                    <li>
                      <p className="fs-18">
                        If you are using Lego Mindstorm EV3, make sure you have
                        the Lego Mindstorm Education EV3 software installed.
                      </p>
                    </li>

                    <li>
                      <p className="fs-18">
                        If you are using Lego Spike Prime, make sure you have
                        the Spike app installed.
                      </p>
                    </li>
                  </ul>
                  <br />
                  <p>
                    <a className="theme-btn-secondary" href="/2024season">
                      Register<span></span>
                    </a>
                  </p>

                  <br />
                </div>
              </div>
              <div className="col-lg-4">
                <img
                  src="assets/images/robotics_star.png"
                  alt="Star Robotics"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container category-card category-bg-1">
            <div className="row">
              <ChallengeStrip
                icon={<RoboticsIcon />}
                category_name="Autonomous Challenge - Super Nova"
                age="13-15"
                team_size="2-3 members"
                third_title="Robot"
                third_text="Lego Mindstorm EV3 | Lego Spike Prime"
              />
            </div>
            <div className="row">
              <div className="col-lg-4">
                <img
                  src="assets/images/robotics_super_nova.png"
                  alt="Super Nova Robotics"
                  width="100%"
                />
              </div>
              <div className="col-lg-8">
                <div className="recipe-detail-content">
                  <div className="category-card-desc">
                    <p className="fs-18">
                      Teams will prepare for the advance challenge by checking
                      “Season Challenge” on the Seasons page under "Aien
                      Planet". In addition, there will be a day-of challenge
                      that will require teams to design, build, and program
                      their robot to complete a mission within the theme of
                      exploring an Alien Planet. Judging will be based on both
                      challenges.
                    </p>
                    <p className="fs-18">
                      For this category, students should bring at least 1 laptop
                      per team. In addition,
                    </p>
                    <ul>
                      <li>
                        <p className="fs-18">
                          If you are using Lego Mindstorm EV3, make sure you
                          have the Lego Mindstorm Education EV3 software
                          installed.
                        </p>
                      </li>

                      <li>
                        <p className="fs-18">
                          If you are using Lego Spike Prime, make sure you have
                          the Spike app installed.
                        </p>
                      </li>
                    </ul>
                    <br />

                    <p>
                      <a className="theme-btn-secondary" href="/2024season">
                        Register<span></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </React.Fragment>
  );
};
export default Robotics;
