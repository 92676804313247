import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import MetaTags from "react-meta-tags";
import Gallery from "react-photo-gallery";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { API_ROOT } from "../../config/env-var";
import Footer from "../footer/Footer";
import Banner from "../header/Banner";
import Header from "../header/Header2";

const GalleryPage = () => {
  const [photos, setPhotos] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const fetchPhotos = async () => {
    let tempPhotos = await axios.get(`${API_ROOT}/gallery`).catch((err) => {
      console.log("Error: ", err);
    });
    console.log("got", tempPhotos.data.results);
    setPhotos(tempPhotos.data.results);
  };

  useEffect(() => {
    fetchPhotos();
  }, []);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <>
      <MetaTags>
        <title>STRIPE Competition</title>
        <meta
          name="description"
          content="STRIPE Competition is an open STEM competition encompassing
        science, technology, robotics, innovation, programming, and engineering. Our competition
        allows students to show case their skills in the field of STEM while
        applying their knowledge to real-world problems in an inclusively diverse cultural environment."
        />
      </MetaTags>
      <Header />
      {/* Breadcrumbs */}
      <Banner title="Gallery" />
      {/* About Us Sec */}
      <section className="sec-space">
        <div className="container">
          <div className="row">
            {photos && <Gallery photos={photos} onClick={openLightbox} />}
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={photos.map((x) => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title,
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default GalleryPage;
