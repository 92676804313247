import React from "react";
import MetaTags from "react-meta-tags";
import Footer from "../footer/Footer";
import Banner from "../header/Banner";
import Header from "../header/Header2";

const Organizers = () => {
  let organizers = [
    {
      company_name: "Zebra Robotics",
      company_description:
        "Zebra Robotics was founded in 2014 to empower people with robotics, coding, and technology. Zebra Robotics now has more than 50 courses, covering robotics, coding, and technology, for kids from Grade 1 to 12. With head offices in Canada and the USA, the company is excited to sponsor the international STRIPE Competition. ",
      company_image: "/assets/images/zebra_logo.png",
      link: "https://zebrarobotics.com",
      width: "75%",
      height: "75%",
    },
  ];
  return (
    <>
      <section className="pt-110 pb-70">
        <div className="container">
          <div className="sec-heading text-center">
            <h2 className="text-uppercase fs-45 mb-40">Organizer</h2>
          </div>
          {organizers.map((p, i) => {
            return (
              <div className="row" key={i}>
                <div className="col-md-5 text-center my-auto">
                  <img
                    src={p.company_image}
                    alt={p.company_name}
                    width={p.width}
                    height={p.height}
                  />
                </div>
                <div className="col-md-7">
                  <a href={p.link}>
                    <h2>{p.company_name}</h2>
                  </a>
                  <p>{p.company_description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

const PremiumSponsors = () => {
  let premiumSponsors = [
    {
      company_name: "Your Company Name Here",
      tier_color: "silverSponsor",
      tier: "Silver Sponsor",
      company_description:
        "Join our Sponsors. Help students pursue their passions in STEM and push themselves and society further by honing their skills in competition." +
        " Reach out to us to join our growing list of sponsors!",
      company_image: "/assets/images/Your Company.png",
      link: "#",
      width: "100%",
      height: "100%",
    },
  ];
  return (
    <>
      <section className="pb-70">
        <div className="container">
          <div className="sec-heading text-center">
            <h2 className="text-uppercase fs-45 mb-40">Sponsors</h2>
          </div>
          {premiumSponsors.map((p, i) => {
            return (
              <div className="row pb-100" key={i}>
                <div className="col-md-5 text-center my-auto">
                  <img
                    src={p.company_image}
                    alt={p.company_name}
                    width={p.width}
                    height={p.height}
                  />
                </div>
                <div className="col-md-7">
                  <a href={p.link}>
                    <h2>{p.company_name}</h2>
                    <h4 className={p.tier_color}>{p.tier}</h4>
                  </a>
                  <p>{p.company_description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

const Supporters = () => {
  let supportersList = [
    {
      image: "https://www.shad.ca/wp-content/uploads/2019/05/SHAD-logo@2x.png",
      name: "SHAD Canada",
      width: 330,
      height: 160,
    },
    {
      image:
        "https://static.wixstatic.com/media/7001cb_eb6b131980a94be595e75a11adbcf0b4~mv2.png/v1/crop/x_0,y_114,w_500,h_264/fill/w_263,h_139,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/BioTroopers%20Logo%20Horizontal%20Transparent.png",
      name: "BioTroopers",
      width: 290,
      height: 160,
    },
    {
      image:
        "https://static.wixstatic.com/media/b2ca9c_0e700fca1887499fbb0474d9207deb7c~mv2.png/v1/crop/x_338,y_410,w_356,h_348/fill/w_164,h_160,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/b2ca9c_0e700fca1887499fbb0474d9207deb7c~mv2.png",
      name: "Girls SySTEM Mentorship",
      width: 180,
      height: 180,
    },
    {
      image:
        "https://letstalkscience.ca/themes/custom/lts_barrio2/assets/logos/LTS-Logo-Light-en.svg",
      name: "Let's Talk Science",
      width: 280,
      height: 180,
    },
  ];

  return (
    <>
      <section className="pt-110 pb-70">
        <div className="container">
          <div className="sec-heading text-center">
            <h2 className="text-uppercase fs-45 mb-40">Our Supporters</h2>
          </div>
          <div className="row">
            {supportersList.map((s, i) => {
              return (
                <div className={`col-md-${12 / supportersList.length}`} key={i}>
                  <img
                    src={s.image}
                    alt={s.name}
                    width={s.width}
                    height={s.height}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

const Sponsor = () => {
  return (
    <>
      {" "}
      <MetaTags>
        <title>Sponsors | STRIPE Competition</title>
        <meta
          name="description"
          content="A List of STRIPE Competition's generious spoonsors and supporters"
        />
        <link
          rel="canonical"
          href="https://www.stripecompetition.com/Sponsor"
        />
      </MetaTags>
      <Header />
      {/*  Breadcrumbs */}
      <Banner link="Sponsor" title="Join Our Sponsors" />
      {/* ContactRegistration */}
      <Organizers />
      <PremiumSponsors />
      {/* <Supporters /> */}
      {/* SponsorForm */}
      {/* <SponsorForm /> */}
      <Footer />
    </>
  );
};
export default Sponsor;
